<template>
  <div class="order-board-view overflow-auto">
    <k-search-bar-section
      hide-assign-option
      hide-edit-option
      hide-delete-option
      hide-selection-info
      @update-sort="updateSort"
      @on-search="updateSearch"
    />
    <div class="board-container d-flex flex-row">
      <!-- board column component -->
      <board-column
        v-for="(pipeline, i) in ORDER_BOARD_VIEW_DEAL_STAGES"
        :key="pipeline.stage"
        :header-text="pipeline.label"
        class="me-2"
      >
        <div class="d-flex flex-column order-list">
          <!-- card component -->
          <k-card
            v-for="(order, j) in [1, 2, 3, 4, 5, 6, 7]"
            :key="order"
            class="order-details d-flex flex-column w-50"
          >
            <div class="w-100 d-flex justify-content-between kp-name">
              <div class="font-inter text-start">
                KingPin x KingPin
              </div>
              <div>
                <b-dropdown
                  size="lg"
                  variant="link"
                  class="k-search-dropdown dropdown-custom-class three-dot-dropdown font-inter"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template #button-content>
                    <div class="three-dot-icon">
                      <svg
                        width="3"
                        height="12"
                        viewBox="0 0 3 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3 1.5C3 2.32843 2.32843 3 1.5 3C0.671573 3 0 2.32843 0 1.5C0 0.671573 0.671573 0 1.5 0C2.32843 0 3 0.671573 3 1.5Z"
                          fill="#C2CFE0"
                        />
                        <path
                          d="M3 6C3 6.82843 2.32843 7.5 1.5 7.5C0.671573 7.5 0 6.82843 0 6C0 5.17157 0.671573 4.5 1.5 4.5C2.32843 4.5 3 5.17157 3 6Z"
                          fill="#C2CFE0"
                        />
                        <path
                          d="M3 10.5C3 11.3284 2.32843 12 1.5 12C0.671573 12 0 11.3284 0 10.5C0 9.67157 0.671573 9 1.5 9C2.32843 9 3 9.67157 3 10.5Z"
                          fill="#C2CFE0"
                        />
                      </svg>
                    </div>
                  </template>
                  <b-dropdown-item href="#">
                    View Order Summary
                  </b-dropdown-item>
                  <b-dropdown-item href="#">
                    Quick Edit
                  </b-dropdown-item>
                  <b-dropdown-item href="#">
                    <div
                      class="w-100 d-flex justify-content-between child-menu"
                    >
                      <div>Assign to users</div>
                      <div>
                        <svg
                          width="5"
                          height="8"
                          viewBox="0 0 5 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.21497 6.80664L3.99998 4.02163L1.17155 1.1932"
                            stroke="#707683"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-item href="#">
                    View Logs
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <div class="d-flex align-items-start kp-amount">
              <span class="amount-key font-inter">Amount:</span>
              <span class="amount-value font-work-sans">$15,123.00</span>
            </div>
            <div
              class="w-100 d-flex justify-content-between font-inter kp-date"
            >
              <div>Created: 31/03/22</div>
              <div
                :id="`info_icon${i}${j}`"
                class="info-icon"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="8"
                    cy="8"
                    r="7.25"
                    stroke="#16ACD9"
                  />
                  <path
                    d="M8.90075 3.57617L8.70332 9.53631H7.24722L7.03744 3.57617H8.90075ZM8.01229 12.4238C7.69968 12.4238 7.44054 12.3292 7.23488 12.14C7.03744 11.9426 6.93872 11.6999 6.93872 11.412C6.93872 11.124 7.03744 10.8855 7.23488 10.6963C7.44054 10.4988 7.69968 10.4001 8.01229 10.4001C8.31667 10.4001 8.56758 10.4988 8.76502 10.6963C8.96245 10.8855 9.06117 11.124 9.06117 11.412C9.06117 11.6999 8.96245 11.9426 8.76502 12.14C8.56758 12.3292 8.31667 12.4238 8.01229 12.4238Z"
                    fill="#16ACD9"
                  />
                </svg>
              </div>
              <b-tooltip
                :target="`info_icon${i}${j}`"
                triggers="hover"
                placement="bottom"
                custom-class="custom-tooltip"
              >
                <div
                  class="d-flex flex-column align-items-start tooltip-content"
                >
                  <div class="row-data">
                    <span class="font-montserrat content-key">Order ID:</span>
                    <span
                      class="font-inter text-primary content-value"
                    >451234134</span>
                  </div>
                  <div class="row-data">
                    <span class="font-montserrat content-key">Order Date:</span>
                    <span class="font-inter content-value">02/02/2022</span>
                  </div>
                  <div class="row-data">
                    <span
                      class="font-montserrat content-key"
                    >Total Units:</span>
                    <span class="font-inter content-value">10,770</span>
                  </div>
                  <div class="row-data">
                    <span
                      class="font-montserrat content-key"
                    >Collection Name:</span>
                    <span
                      class="font-inter content-value"
                    >Winter Collection 2022</span>
                  </div>
                </div>
              </b-tooltip>
            </div>
          </k-card>
        </div>
      </board-column>
    </div>
  </div>
</template>

<script>
import {
  BTooltip,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import { KCard, BoardColumn, KSearchBarSection } from '@kingpin-global/kingpin-ui'
import { KSearchAndSortMixin } from '@/mixins/k-searbar-section.mixin'
import { ORDER_BOARD_VIEW_DEAL_STAGES } from '@/constants'

export default {
  name: 'OrderBoard',
  components: {
    KCard,
    BoardColumn,
    KSearchBarSection,
    BTooltip,
    BDropdown,
    BDropdownItem,
  },
  mixins: [KSearchAndSortMixin],
  data() {
    return {
      ORDER_BOARD_VIEW_DEAL_STAGES,
    }
  },
}
</script>

<style lang="scss">
@import "@/@core/scss/vue/pages/kp-board-view-page.scss";
</style>
